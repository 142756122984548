import React, { useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';
import Image from '../components/image';
import Animation from '../images/ani-graph.inline.svg';
import Bgimg from '../images/box-mask.svg';
import Bgimg2 from '../images/top-right.svg';

const ServicesPage = () => {
    const [isOpen, setOpen] = useState('false');
    const [isOpen2, setOpen2] = useState('false');
    const [isOpen3, setOpen3] = useState('false');
    const [isOpen4, setOpen4] = useState('false');

    const sToggle = () => {
        setOpen(!isOpen);
    };

    const sToggle2 = () => {
        setOpen2(!isOpen2);
    };

    const sToggle3 = () => {
        setOpen3(!isOpen3);
    };
    
    const sToggle4 = () => {
        setOpen4(!isOpen4);
    };

    return (
        <Layout>
            <Seo
                title="Services"
                description="Protection of Assets and Wealth"
            />
            <div className="app-title">
                <div className="title-txt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        Services
                    </h1>
                    <p
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="600"
                        data-sal-easing="easeOutCubic"
                    >
                        Protection of Assets and Wealth
                    </p>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div
                                className={isOpen ? 'sitem' : 'sitem sopen'}
                                onClick={sToggle}
                                onKeyDown={sToggle}
                                aria-hidden="true"
                                style={{ backgroundImage: `url(${Bgimg})` }}
                            >
                                <div className="sitem__title">
                                    <h1>Superannuation</h1>
                                </div>
                                <div className="sitem__desc">
                                    <p>
                                        Planning for retirement is one of life's
                                        most important financial considerations.
                                        The actions you take today will
                                        determine your lifestyle in the future.
                                    </p>
                                    <p className="phide">
                                        Superannuation is one of the most
                                        tax-effective long-term retirement
                                        savings vehicles, however it can be
                                        difficult to keep up with changing rules
                                        and regulations. We can explain the
                                        rules and provide you with strategies to
                                        help you maximise your retirement
                                        savings.
                                    </p>
                                </div>
                                <div className="sitem__arrow">
                                    <Image
                                        src="down.svg"
                                        alt="More"
                                        className={isOpen ? '' : 'sopen'}
                                    />
                                </div>
                            </div>
                            <div className="slink">
                                <Link to="/appointment" className="btn">
                                    Book an Appointment
                                </Link>
                            </div>
                            <div
                                className={isOpen2 ? 'sitem' : 'sitem sopen'}
                                onClick={sToggle2}
                                onKeyDown={sToggle2}
                                aria-hidden="true"
                                style={{ backgroundImage: `url(${Bgimg})` }}
                            >
                                <div className="sitem__title">
                                    <h1>Insurance</h1>
                                </div>
                                <div className="sitem__desc">
                                    <p>
                                        Things happen... some expected, some
                                        unexpected. We owe it to ourselves and
                                        our families to ensure we are protected
                                        from unexpected events such as death,
                                        disablement, serious illness or injury.
                                    </p>
                                    <p className="phide">
                                        Your ultimate wealth will be determined
                                        by your existing assets (including
                                        property and shares), as well as your
                                        ability to earn money from your own
                                        exertions. Failure to ensure that you
                                        have adequate insurance in place could
                                        seriously diminish your future standard
                                        of living. We can assist with
                                        appropriate insurance and provide peace
                                        of mind.
                                    </p>
                                </div>
                                <div className="sitem__arrow">
                                    <Image
                                        src="down.svg"
                                        alt="More"
                                        className={isOpen2 ? '' : 'sopen'}
                                    />
                                </div>
                            </div>
                            <div className="slink">
                                <Link to="/appointment" className="btn">
                                    Book an Appointment
                                </Link>
                            </div>
                            <div
                                className={isOpen3 ? 'sitem' : 'sitem sopen'}
                                onClick={sToggle3}
                                onKeyDown={sToggle3}
                                aria-hidden="true"
                                style={{ backgroundImage: `url(${Bgimg})` }}
                            >
                                <div className="sitem__title">
                                    <h1>Estate Planning</h1>
                                </div>
                                <div className="sitem__desc">
                                    <p>
                                        No one can predict the future, but one
                                        thing’s for sure. If you leave
                                        unanswered questions about how to settle
                                        your affairs if you become incapacitated
                                        or die, then life could become difficult
                                        for those left to manage your estate.
                                    </p>
                                    <p className="phide">
                                        Estate planning provides peace of mind
                                        that your assets are passed on to your
                                        beneficiaries in the most simple and tax
                                        effective way.
                                    </p>
                                </div>
                                <div className="sitem__arrow">
                                    <Image
                                        src="down.svg"
                                        alt="More"
                                        className={isOpen3 ? '' : 'sopen'}
                                    />
                                </div>
                            </div>
                            <div className="slink">
                                <Link to="/appointment" className="btn">
                                    Book an Appointment
                                </Link>
                            </div>
                            <div
                                className={isOpen4 ? 'sitem' : 'sitem sopen'}
                                onClick={sToggle4}
                                onKeyDown={sToggle4}
                                aria-hidden="true"
                                style={{ backgroundImage: `url(${Bgimg})` }}
                            >
                                <div className="sitem__title">
                                    <h1>Investment</h1>
                                </div>
                                <div className="sitem__desc">
                                    <p>
                                        However you choose to invest, whether it
                                        be individually, via a trust or company,
                                        or in your self-managed superannuation
                                        fund, we can assist in creating a
                                        diversified investment portfolio.
                                    </p>
                                    <p className="phide">
                                        Our investment philosophy is:
                                    </p>
                                    <ul className="phide">
                                        <li>
                                            To invest in line with your
                                            tolerance to investment risk
                                        </li>
                                        <li>Protect your capital</li>
                                        <li>Generate income</li>
                                    </ul>
                                </div>
                                <div className="sitem__arrow">
                                    <Image
                                        src="down.svg"
                                        alt="More"
                                        className={isOpen4 ? '' : 'sopen'}
                                    />
                                </div>
                            </div>
                            <div className="slink">
                                <Link to="/appointment" className="btn">
                                    Book an Appointment
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="outro"
                style={{ backgroundImage: `url(${Bgimg2})` }}
            >
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="services-process">
                                <h1
                                    data-sal="slide-up"
                                    data-sal-duration="300"
                                    data-sal-easing="easeOutCubic"
                                >
                                    Our Process
                                </h1>
                                <ul className="process">
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <strong>1.</strong>
                                        <span>
                                            <em>defining</em> the <em>scope</em>{' '}
                                            of the engagement.
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <strong>2.</strong>
                                        <span>
                                            <em>identifying</em> your goals.
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <strong>3.</strong>
                                        <span>
                                            <em>assessing</em> your financial
                                            situation
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <strong>4.</strong>
                                        <span>
                                            <em>preparing</em> your financial
                                            plan
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <strong>5.</strong>
                                        <span>
                                            <em>implementing</em> the
                                            recommendations
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <strong>6.</strong>
                                        <span>
                                            <em>reviewing</em> the plan
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ServicesPage;
